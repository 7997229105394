export function getCookie(name) {
    const cookies = document.cookie.split('; ');
    for (let cookie of cookies) {
        const [key, value] = cookie.split('=');
        if (key === name) {
            return decodeURIComponent(value);
        }
    }
    return null; // Если куки с таким именем не найдено
}

export function deleteAllCookies() {
    // Получаем все куки
    const cookies = document.cookie.split(";");

    // Удаляем каждую куку
    for (let cookie of cookies) {
        // Получаем имя куки
        const cookieName = cookie.split("=")[0].trim();

        // Устанавливаем её с истёкшим сроком действия
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
}

export const setCookie = (key, value, days) => {
    const date = new Date();
    const domainName = window.location.hostname;
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // days → миллисекунды
    document.cookie = `${key}=${value}; expires=${date.toUTCString()}; path=/; secure; domain=.${domainName}`;
};
