import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import { PageHeaderBlock, PageHeaderTitle} from "../../components/common/styles";
import {
    fetchUserAdministration,
} from "../../store/administration/actions";
import {ButtonSimple} from "../../components/common/Buttons";
import {initialAuth, setUser} from "../../store/auth/actions";
import Api from "../../api";
import {errorParser, setError} from "../../store/preloader/actions";
import {REDIRECT_URL} from "../../utils/constants";
import {deleteAllCookies, setCookie} from "../../Services/cookie";

class LoginPage extends Component {

    getTempLogin() {
        let href = window.location.href
        if( href.includes('localhost')) {
            return 'timofey@webinnovations.ru'
        }
         return  ''
    }

    getTempPassword() {
        let href = window.location.href
        if( href.includes('localhost')) {
            return 'a-o23X8qS-'
        }
        return  ''
    }

    componentDidMount() {
        // eslint-disable-next-line react/prop-types
        const currentUrl = this.props.location.pathname;
        const trySSO = localStorage.getItem("trySSO");
        if( currentUrl.includes('try_redirect_sso')) {
            const { history } = this.props;
            if(Number(trySSO || '0') === 1) {
                localStorage.setItem("trySSO", '0');
                // eslint-disable-next-line react/prop-types
                history.push('/login');
            } else {
                localStorage.setItem("trySSO", '1');
                window.location.href = REDIRECT_URL;
            }
        }

        if( currentUrl.includes('logout')) {
             deleteAllCookies();
            const userDef =  {
                admin: false,
                email: "",
                fullName: "",
                id: ""
            };
            this.props.setUser(userDef);
        }
    }


    state = {login: this.getTempLogin(), password: this.getTempPassword(), isAuth: false, wait: false}

    render() {
        const {
            initialAuth,
            setError,
            history
        } = this.props;

        const {login, password,isAuth,wait } = this.state
        const loginNow = async ()=>{
            if(!password) return ;

            try {
                this.setState({wait: true})
                // debugger
                const res=  await Api.auth.adLogin(login, password);
                // debugger
                console.log(res)
                const newToken =  res.data.token;
                await initialAuth(newToken);
                this.setState({isAuth: true});
            } catch (error) {
                console.log('ошибка авторизацию', error)
                setError(`Ошибка авторизации - ${ errorParser(error) || error.message }`);
            }
            this.setState({wait: false})
        }

        const setLogin = (val) => {
            this.setState({login: val})
        }
        const setPassword = (val) => {
            this.setState({password: val})
        }
        const handleKeyPress = async (event)=>{
            if (event.key === "Enter") {
                await loginNow()
            }
        }







        return (
            <div>
                <PageHeaderBlock margin="10px 0 30px 0">
                    <PageHeaderTitle>Авторизация</ PageHeaderTitle>
                </PageHeaderBlock>
                <div className='loginForm'>
                    {!isAuth && <div className='formBlock'>
                        <input placeholder='Логин'
                               className='inputBlock'
                               value={login}
                               onChange={e => setLogin(e.target.value)}
                               onKeyPress={handleKeyPress}
                        />
                        <input placeholder='Пароль'
                               type='password'
                               className='inputBlock'
                               value={password}
                               onChange={e => setPassword(e.target.value)}
                               onKeyPress={handleKeyPress}
                        />
                        <ButtonSimple text="Войти" onClick={loginNow} disabled={wait || !password}/>
                    </div>}

                    {isAuth && <div className='formBlock goLink'>
                        <Link to="/registry"><ButtonSimple text="К проектам"/></Link>
                    </div>}

                </div>
            </div>);
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    loginFun: () => dispatch(fetchUserAdministration()),
    initialAuth: accessToken => dispatch(initialAuth(accessToken)),
    setError: error=> dispatch(setError(error)),
    setUser: (user)=> dispatch(setUser(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LoginPage));
