import auth from "./auth";
import gitlab from "./gitlab";
import project from "./project";
import dictionaries from "./dictionaries";
import releases from "./releases";
import documents from "./documents";
import notifications from "./notifications";
import pipelines from "./pipelines";
import tasks from "./tasks";
import mailing from "./mailing";
import eventsLog from "./eventsLog";
import administration from "./administration";
import reports from "./reports";
import axios from "axios";
import {getCookie} from "../Services/cookie";
axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
  config.withCredentials = true; // Включаем отправку куки
  return config;
}, error => {
  return Promise.reject(error);
});




const Api = {
  auth,
  gitlab,
  project,
  dictionaries,
  releases,
  documents,
  notifications,
  pipelines,
  tasks,
  mailing,
  eventsLog,
  administration,
  reports
};

export default Api;
