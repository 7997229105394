import { fromJS } from "immutable";
import { SET_USER } from "./constants";

const initialState = fromJS({
  user: {
    admin: false,
    email: "",
    fullName: "",
    id: ""
  }
});

const auth = (state = initialState, action) => {
  if (action.type === SET_USER) {
    return state.set("user", fromJS(action.payload.user));
  } 
  return state;
  
};

export default auth;
